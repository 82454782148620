<template>
  <div>
    <branches-edit></branches-edit>
  </div>
</template>

<script>
import BranchesEdit from "@/components/ecologist/branches/branchesEdit";
export default {
  name: "BranchesEditView",
  components: { BranchesEdit },
};
</script>

<style scoped></style>
